import React, { useEffect, useRef, useState } from "react";
import TicketingListComp from "../../components/ticketing/ticketList";
import TicketingBoardComp from "../../components/ticketing/ticketBoard";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  allTicketsRequest,
  createTicketRequest,
  getAssignedTicket,
  updateTicketRequest,
} from "../../redux/features/ticketing/ticketingSlice";
import toast from "react-hot-toast";
import { EmptyStateComp, MainLoader } from "../../components/utils";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import ClickOutside, {
  subscriptionPlans,
} from "../../components/utils/helpers";
import {
  FaChevronDown,
  FaChevronRight,
  FaRegUser,
  FaSpinner,
  FaTimesCircle,
} from "react-icons/fa";
import CustomDropdown from "../../reuseable/dropDown";
import { allTeamRequest } from "../../redux/features/teams/teamSlice";
import notfound from "../../assets/state-notfound.svg";

export default function TicketingPage() {
  const isMounted = useRef(false);
  const [viewType, setViewType] = useState("board");
  const [createTicketModal, setCreateTicketModal] = useState(false);
  const [editTicketModal, setEditTicketModal] = useState(false);
  const [ticketTitle, setTicketTitle] = useState("");
  const [assignee, setAssignee] = useState("");
  const [ticketDescription, setTicketDescription] = useState("");
  const [ticketStatus, setTicketStatus] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [subOpen, setSubOpen] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeItem, setActiveItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allTeamData, setAllTeamData] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [filteredAssignee, setFilteredAssignee] = useState([]);
  const [allChecked, setAllChecked] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [isChecked, setIsChecked] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const fileInputRef = useRef(null);
  const [multiChecks, setMultiChecks] = useState([]);
  const [sessionId, setSessionId] = useState("");

  const allTicketsState = useSelector((state) => state.ticketingReducer);
  const routeState = useSelector((state) => state.routeReducer);

  let orgId = Cookies.get("orgId") || "";
  let ticketTags = ["Open", "Ongoing", "Failed", "Done"];
  const views = ["board", "list"];
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const countWords = (text) => {
    const words = text.trim().split(/\s+/);
    return words.length;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFilePreview(null);
    }
  };

  const handleClick = (page) => {
    setViewType(page);
    setSearchParams({
      tab: page,
    });
  };

  useEffect(() => {
    if (!searchParams.size)
      setSearchParams(() => {
        const param = { tab: views[0] };
        setViewType(param.tab);
        return param;
      });
  }, []);

  let location = useLocation();
  const { state } = location;
  const planName = routeState?.planName;
  const isPlanActive = routeState?.orgSubscription?.is_active || "";

  let dispatch = useDispatch();

  const node = ClickOutside(() => {
    setIsOpen(false);
    setSubOpen(false);
    setStatusOpen(false);
    setActiveItem(null);
  });

  const handleOpen = () => {
    setCreateTicketModal(!createTicketModal);
  };

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const fetchAllTeam = async () => {
    setLoading(true);
    if (routeState?.profileInfo?.id) {
      await dispatch(
        allTeamRequest({ organization_id: routeState?.profileInfo?.id })
      )
        .then((res) => {
          setLoading(false);
          if (res?.payload?.status === 200) {
            setAllTeamData(res?.payload?.data);
            setFilteredOptions(res?.payload?.data);
          } else {
            toast.error(res?.payload?.data?.detail);
          }
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;
    if (routeState?.profileInfo && routeState?.profileInfo?.id) {
      fetchAllTeam();
    }
  }, [routeState]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filtered = allTeamData.filter(
      (option) =>
        option?.name && option?.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredOptions(filtered);
    setIsDropdownOpen(true);
  };

  const handleOptionSelect = (option) => {
    setFilteredAssignee(option);
    setIsDropdownOpen(false);
    setIsSearched(true);
    dispatch(
      getAssignedTicket({
        organisation_id: orgId,
        assigned_to: option?.email,
      })
    )
      .then((res) => {
        if (res?.payload?.status === 200) {
          getAllTickets();
        } else {
          toast.error(res?.payload?.data?.detail);
        }
      })
      .catch((err) => {});
  };

  const handleItemClick = (itemName) => {
    if (itemName === "assignees") {
      setSubOpen(!subOpen);
      setStatusOpen(false);
    } else if (itemName === "status") {
      setStatusOpen(!statusOpen);
      setSubOpen(false);
    } else {
    }
  };

  useEffect(() => {
    if (!isDropdownOpen) {
      setSearchQuery("");
      setFilteredOptions(allTeamData);
    }
  }, [isDropdownOpen, allTeamData]);

  useEffect(() => {
    getAllTickets();
  }, [isSearched]);

  let getAllTickets = () => {
    dispatch(allTicketsRequest())
      .then((res) => {
        if (res?.payload?.status === 200) {
          return;
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  let createTicket = () => {
    if (!ticketTitle) {
      toast.error("Please input a ticket title");
      return;
    }
    if (!assignee) {
      toast.error("Please input an assignee");
      return;
    }
    if (!ticketDescription) {
      toast.error("Please input a ticket description");
      return;
    }
    if (ticketDescription && assignee && ticketTitle) {
      setLoading(true);
      let data = {
        title: ticketTitle,
        assigned_to: assignee,
        organisation_id: orgId,
        description: ticketDescription,
      };
      dispatch(createTicketRequest(data))
        .then((res) => {
          if (res?.payload?.status === 200) {
            getAllTickets();
            toast.success("Ticket created successfully");
            closeCreateModal();
            setAssignee("");
          } else {
            toast.error(res?.payload?.data?.response);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  let updateTicket = () => {
    setLoading(true);
    let data = {
      title: ticketTitle,
      organisation_id: orgId,
      description: ticketDescription,
      assigned_to: assignee,
      ticket_id: ticketId,
      ticket_status: ticketStatus?.value,
    };

    dispatch(updateTicketRequest(data))
      .then((res) => {
        if (res?.payload?.status === true) {
          getAllTickets();
          setLoading(false);
          toast.success("Ticket updated successfully");
          closeEditModal();
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  let closeCreateModal = () => {
    setTicketTitle("");
    setTicketDescription("");
    setCreateTicketModal(false);
    setFilePreview(null);
    setSelectedFile(null);
  };

  let closeEditModal = () => {
    setTicketTitle("");
    setTicketDescription("");
    setTicketStatus("");
    setTicketId("");
    setEditTicketModal(false);
  };

  let toggleEditModal = (activeTicket) => {
    setTicketTitle(activeTicket?.title);
    setTicketDescription(activeTicket?.description);
    setAssignee(activeTicket?.assigned_to?.email);
    setTicketStatus({
      value: activeTicket?.status,
      label: activeTicket?.status,
    });
    setTicketId(activeTicket?.id);
    setEditTicketModal(true);
    setSessionId(activeTicket?.session_id);
  };

  let updateTicketByMovement = (activeTicket) => {
    let data = {
      title: activeTicket?.title,
      description: activeTicket?.description,
      ticket_id: activeTicket?.ticket_id,
      ticket_status: activeTicket?.ticket_status,
      assigned_to: activeTicket?.assigned_to?.email,
    };
    dispatch(updateTicketRequest(data))
      .then((res) => {
        if (res?.payload?.status === true) {
          if (filteredAssignee.name) {
            handleOptionSelect(activeTicket.assigned_to);
          } else {
            getAllTickets();
          }
          toast.success("Ticket Moved successfully");
          closeEditModal();
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  const handleStatusChange = (status) => {
    setIsChecked(status);
  };

  const handleSaveChanges = () => {
    if (!isChecked) {
      toast.error("Please select a status to move ticket");
    } else {
      setLoading(true);
      for (let i = 0; i < multiChecks.length; i++) {
        const updatedTicket = {
          title: multiChecks[i]?.title,
          description: multiChecks[i]?.description,
          ticket_id: multiChecks[i]?.ticket_id,
          ticket_status: isChecked,
          assigned_to: multiChecks[i]?.assigned_to,
        };
        updateTicketByMovement(updatedTicket);
      }
    }
    setLoading(false);
  };

  return (
    <div className="">
      {(planName === subscriptionPlans.PROFESSIONAL ||
        planName === subscriptionPlans.ADVANCED) &&
      isPlanActive === true ? (
        <div className="ticketing-page-area">
          {createTicketModal && (
            <div className="main-modal">
              <div className="main-modal-content bg-white w-1/3 shadow card col-md-6 col-lg-4 mx-auto">
                <span onClick={closeCreateModal}>
                  <i className="ri-close-line close-modal" />
                </span>
                <div className="card-body">
                  <div className="main-modal-body">
                    <h5 className="mb-0 font-PJMedium text-[20px]">
                      Create Ticket
                    </h5>
                    <div className="mt-3 border-t pt-8 font-inter">
                      <div>
                        <label htmlFor="name" className="font-medium">
                          Ticket Title
                        </label>
                        <br />
                        <input
                          type="text"
                          value={ticketTitle}
                          required
                          className="border mt-4 px-5 w-full"
                          onChange={(e) => setTicketTitle(e.target.value)}
                          placeholder="Enter ticket title"
                        />
                      </div>
                      <div className="mt-3">
                        <label htmlFor="assignee" className="font-medium">
                          Assignee
                        </label>
                        <br />
                        <select
                          value={assignee}
                          onChange={(e) => setAssignee(e.target.value)}
                          className="border mt-4 px-5 w-full"
                        >
                          <option value="">Select an assignee</option>
                          {allTeamData.map((member) => (
                            <option key={member.id} value={member.email}>
                              <div className="flex flex-col">
                                <div>{member.name}</div>
                              </div>
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mt-3 pt-8 font-inter">
                        <label htmlFor="email" className="font-medium">
                          Ticket Description
                        </label>
                        <br />
                        <textarea
                          className="border mt-4 py-3 px-5 w-full"
                          rows="3"
                          placeholder="Enter ticket description"
                          value={ticketDescription}
                          onChange={(e) => setTicketDescription(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="mt-5">
                        <p className="font-inter text-sm leading-[21px] tracking-[-4%]">
                          Upload image (optional)
                        </p>
                        <div
                          className="rounded-lg border flex mt-2 flex-col items-center justify-center px-4 py-8"
                          onClick={handleIconClick}
                        >
                          {filePreview ? (
                            <img
                              src={filePreview}
                              alt="Preview"
                              className="mt-5 w-full h-auto"
                            />
                          ) : (
                            <div className="">
                              <img
                                src={notfound}
                                alt=""
                                className="mt-5 flex justify-center items-center"
                              />
                              <p className="font-inter text-xs leading-[17.21px] opacity-30 text-[#212636] text-center tracking-[-4%]">
                                Choose the image you want to upload
                              </p>
                            </div>
                          )}
                        </div>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                      </div>
                      <button
                        className="bg-primary flex justify-center items-center w-[186px] h-[43px] rounded-lg text-light py-3 mt-8 w-100"
                        onClick={createTicket}
                      >
                        {loading ? (
                          <FaSpinner className="animate-spin " />
                        ) : (
                          `Create Ticket`
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {editTicketModal && (
            <div className="main-modal font-inter">
              <div className="main-modal-content bg-white w-1/3 shadow card col-md-6 col-lg-4 mx-auto">
                <span onClick={closeEditModal}>
                  <i className="ri-close-line close-modal" />
                </span>
                <div className="card-body">
                  <div className="main-modal-body">
                    <h5 className="mb-0 font-PJMedium text-[20px]">
                      Edit Ticket
                    </h5>
                    <p>Make necessary changes to ticket details</p>

                    <div className="mt-3 border-t pt-8 font-inter">
                      <div className="">
                        <label htmlFor="assignee" className="font-medium">
                          Assignee
                        </label>{" "}
                        <br />
                        <select
                          value={assignee}
                          onChange={(e) => setAssignee(e.target.value)}
                          className="border mt-2 px-5 w-full"
                        >
                          <option value="">Select an assignee</option>
                          {allTeamData.map((member) => (
                            <option key={member.id} value={member.email}>
                              <div className="flex flex-col">
                                <div>{member.name ? member.name : ""}</div>
                              </div>
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mt-3">
                        <label className="font-medium">User</label> <br />
                        <input
                          type="text"
                          value={ticketTitle}
                          required
                          className="border mt-2 px-5 w-full"
                          onChange={(e) => setTicketTitle(e.target.value)}
                          placeholder="Enter ticket title"
                        />
                      </div>

                      <div className="mt-3">
                        <label className="font-medium">Ticket Content</label>{" "}
                        <br />
                        <textarea
                          className="border w-full mt-2 px-5 pt-4"
                          rows="3"
                          maxLength={2000}
                          placeholder="Enter ticket description"
                          value={ticketDescription}
                          onChange={(e) => setTicketDescription(e.target.value)}
                          onInput={(e) => {
                            const inputText = e.target.value;
                            const currentWordCount = countWords(inputText);
                            setWordCount(currentWordCount);
                            if (currentWordCount <= 2000) {
                              setTicketDescription(inputText);
                            }
                          }}
                        ></textarea>
                        <p className="text-sm text-gray-500">
                          {wordCount} out of 2000 words
                        </p>
                      </div>

                      <div className="mt-5">
                        <p className="font-inter text-sm leading-[21px] tracking-[-4%]">
                          Upload image (optional)
                        </p>
                        <div
                          className="rounded-lg border flex mt-2 flex-col items-center justify-center px-4 py-8"
                          onClick={handleIconClick}
                        >
                          {filePreview ? (
                            <img
                              src={filePreview}
                              alt="Preview"
                              className="mt-5 w-full h-auto"
                            />
                          ) : (
                            <div>
                              <img
                                src={notfound}
                                alt=""
                                className="mt-5 flex justify-center items-center"
                              />
                              <p className="font-inter text-xs leading-[17.21px] opacity-30 text-[#212636] text-center tracking-[-4%]">
                                Choose the image you want to upload
                              </p>
                            </div>
                          )}
                        </div>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                      </div>

                      <hr className="mt-8" />

                      <div className="flex justify-between gap-4">
                        {sessionId && (
                          <div className="mb-2">
                            <Link
                              state={{
                                ...state,
                                session_id: sessionId,
                              }}
                              to={`/Messages?sessionId=${sessionId}`}
                              className="bg-white border-[#B5B5B5] border flex justify-center items-center font-inter text-base w-[186px] h-[43px] rounded-lg text-primary py-3 mt-8 w-100"
                            >
                              Go to Conversation
                            </Link>
                          </div>
                        )}

                        <button
                          className=" bg-primary flex justify-center items-center w-[186px] h-[43px] rounded-lg text-light py-3 mt-8 w-100"
                          onClick={updateTicket}
                        >
                          {loading ? (
                            <div>
                              <FaSpinner className="animate-spin" />
                            </div>
                          ) : (
                            `Save Changes`
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="">
            {multiChecks.length > 0 && (
              <div
                key={ticketTags[0]}
                className={`bg-[#F9F9F9] p-3 flex items-center justify-between`}
              >
                <div className="flex gap-2">
                  <p className="font-regularInter text-sm leading-[21.98px] text-[#667085] ">
                    {multiChecks.length} selected |
                  </p>
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      setMultiChecks([]);
                      setIsChecked("");
                    }}
                  >
                    Deselect
                  </p>
                </div>

                <div className="mt-1 font-inter text-sm  flex items-center gap-2 leading-[24px] tracking-[-4%]">
                  <p className="mr-6">Move selected fields to</p>
                  <div className="flex gap-2 items-center">
                    <>
                      <input
                        type="checkbox"
                        id="open"
                        name="moveTo"
                        value="open"
                        className={`open-checkbox  ${
                          isChecked ? "bg-[#51376A]" : ""
                        }`}
                        checked={isChecked === "open"}
                        onChange={(e) => handleStatusChange(e.target.value)}
                      />
                      <label
                        className="mt-2 font-PJMedium text-xs leading-[30px] tracking-[-0.2px] text-[#565E73] "
                        htmlFor="open"
                      >
                        Open
                      </label>
                    </>

                    <>
                      <input
                        type="checkbox"
                        id="ongoing"
                        name="moveTo"
                        value="ongoing"
                        className="ongoing-checkbox"
                        checked={isChecked === "ongoing"}
                        onChange={(e) => handleStatusChange(e.target.value)}
                      />

                      <label
                        className="mt-2 font-PJMedium text-xs leading-[30px] tracking-[-0.2px] text-[#565E73]"
                        htmlFor="ongoing"
                      >
                        Ongoing
                      </label>
                    </>

                    <>
                      <input
                        type="checkbox"
                        id="blocked"
                        name="moveTo"
                        value="blocked"
                        className="blocked-checkbox"
                        checked={isChecked === "blocked"}
                        onChange={(e) => handleStatusChange(e.target.value)}
                      />
                      <label
                        className="mt-2 font-PJMedium text-xs leading-[30px] tracking-[-0.2px] text-[#565E73]"
                        htmlFor="blocked"
                      >
                        Blocked
                      </label>
                    </>

                    {/* {multiChecks.some(item => item.ticket_status !== 'done') && ( */}
                    <>
                      <input
                        type="checkbox"
                        id="done"
                        name="moveTo"
                        value="done"
                        className="done-checkbox"
                        checked={isChecked === "done"}
                        onChange={(e) => handleStatusChange(e.target.value)}
                      />
                      <label
                        className="mt-2 font-PJMedium text-xs leading-[30px] tracking-[-0.2px] text-[#565E73]"
                        htmlFor="done"
                      >
                        Done
                      </label>
                    </>
                    {/* )} */}
                  </div>
                  <button
                    onClick={handleSaveChanges}
                    className="border-[0.4px] border-[#E8E4ED] px-4 py-[10px] rounded-[10px] ml-4 shadow"
                  >
                    {loading ? (
                      <div>
                        <FaSpinner className="animate-spin" />
                        {/* <span className="sr-only"> Loading...</span> */}
                      </div>
                    ) : (
                      `Save Changes`
                    )}
                  </button>
                </div>
              </div>
            )}

            <div className="mt-4">
              <div className="flex align-center justify-end">
                <div className="pt-3 flex justify-end">
                  <div className="flex justify-center items-center gap-3 ">
                    {filteredAssignee?.name && (
                      <div className="border rounded-lg p-2 bg-light shadow-inner-lg hover:shadow-md transition duration-300">
                        <div className="flex justify-between items-center font-inter text-sm leading-[20.3px] tracking-[-2%]">
                          <span className="mr-2">Assignee:</span>
                          <span
                            className="cursor-pointer flex items-center gap-1"
                            onClick={() => {
                              setFilteredAssignee("");
                              fetchAllTeam();
                              setIsSearched("");
                            }}
                          >
                            {filteredAssignee.name}{" "}
                            <FaTimesCircle className="text-primary" />
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="dropdown relative" ref={node}>
                      <button
                        className="dropdown-toggle relative border px-3 py-2 rounded-[10px] flex gap-2 items-center "
                        onClick={() => {
                          setIsOpen(!isOpen);
                        }}
                      >
                        Filter{" "}
                        <i className="">
                          <FaChevronDown size={10} />
                        </i>
                      </button>
                      {isOpen && (
                        <div className="border py-2 px-2 w-[110px] bg-white absolute z-50  rounded-lg ">
                          <div className="dropdownBody">
                            <p
                              onClick={() => handleItemClick("assignees")}
                              className={`itemDrop flex gap-2 items-center cursor-pointer ${
                                activeItem === "assignees" ? "active" : ""
                              }`}
                            >
                              <FaRegUser />
                              Assignees
                              <FaChevronRight size={10} />
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    {subOpen && (
                      <div className="relative" ref={node}>
                        <div className="sub-dropdown border absolute bg-white z-50 right-[78px] mt-5 rounded-lg py-4 px-3">
                          <div>
                            <div className="flex justify-between items-center">
                              <input
                                className=""
                                type="text"
                                // value={search}
                                onChange={handleSearch}
                                onFocus={() => setIsDropdownOpen(true)}
                                onBlur={() => setIsDropdownOpen(false)}
                                placeholder="Search User"
                              />
                              <span className="">
                                <i className="ri ri-search-line " />
                              </span>
                            </div>
                            {filteredOptions.map((option, index) => (
                              <div
                                key={index}
                                onClick={() => handleOptionSelect(option)}
                                style={{
                                  padding: "0px 2px 2px 2px",
                                  cursor: "pointer",
                                  borderBottom:
                                    index !== filteredOptions.length - 1
                                      ? "1px solid #eee"
                                      : "none",
                                }}
                              >
                                <div className="dropdownlist flex gap-4 py-2">
                                  <span className=" bg-primary text-light w-10 h-10 rounded-full text-center flex justify-center items-center font-PJ-semibold text-sm">
                                    {option?.name
                                      ?.split(" ")[0]
                                      ?.charAt(0)
                                      ?.toUpperCase()}
                                    {option?.name?.split(" ")[1]
                                      ? option?.name
                                          .split(" ")[1]
                                          .charAt(0)
                                          .toUpperCase()
                                      : ""}
                                  </span>
                                  <div style={{ fontSize: "14px" }}>
                                    <div style={{ fontWeight: "bold" }}>
                                      {option.name}
                                    </div>
                                    <div>{option.email}</div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}

                    <p
                      className={`py-2 px-4 rounded-[10px] text-center  font-inter text-base leading-[23.2px] tracking-[-2%] ms-4 cursor-pointer ${
                        viewType === "list" ? "bg-primary text-light" : "border"
                      }`}
                      onClick={() => {
                        setViewType("list");
                        navigate(`/ticketing/?tab=list`);
                      }}
                    >
                      List
                    </p>
                    <p
                      className={`py-2 px-4 rounded-[10px] text-center  font-inter text-base leading-[23.2px] tracking-[-2%] ms-4 cursor-pointer ${
                        viewType === "board"
                          ? "bg-primary text-light"
                          : "border"
                      }`}
                      onClick={() => {
                        setViewType("board");
                        navigate(`/ticketing/?tab=board`);
                      }}
                    >
                      Board
                    </p>

                    <button
                      onClick={handleOpen}
                      className="text-center text-light font-inter text-base leading-[23.2px] tracking-[-2%] bg-primary py-2 px-3 rounded-[10px]  "
                    >
                      <span className="text-[21px] font-PJ-medium text-sm">
                        +
                      </span>{" "}
                      Add New Ticket
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {allTicketsState?.isLoading ? (
            <MainLoader />
          ) : (
            <div className="mt-4">
              {viewType === "list" && (
                <TicketingListComp
                  allTickets={
                    isSearched
                      ? allTicketsState?.assignedResp?.data
                      : allTicketsState?.allTickets?.data
                  }
                  toggleEditModal={toggleEditModal}
                  updateTicketByMovement={updateTicketByMovement}
                  updateTicketIsLoading={allTicketsState?.ticketUpdateIsLoading}
                />
              )}
              {viewType === "board" && (
                <TicketingBoardComp
                  assignee={assignee}
                  setAssignee={setAssignee}
                  allTickets={
                    isSearched
                      ? allTicketsState?.assignedResp?.data
                      : allTicketsState?.allTickets?.data
                  }
                  toggleEditModal={toggleEditModal}
                  updateTicketByMovement={updateTicketByMovement}
                  updateTicketIsLoading={allTicketsState?.ticketUpdateIsLoading}
                  setMultiChecks={setMultiChecks}
                  multiChecks={multiChecks}
                />
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          <div>
            <EmptyStateComp
              desc={
                isPlanActive === true ? (
                  <>
                    You currently have no ticket created. Go to messages, click
                    on a conversation, and create a ticket from the three dotted
                    icon.
                  </>
                ) : (
                  "You have no active subscription, your tickets will appear here as soon as you subscribe."
                )
              }
              ctaValue={"Go to message "}
            />
          </div>
        </div>
      )}
    </div>
  );
}
