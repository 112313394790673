import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import global from '../../../redux/constants/global'
import { profileInfoRequest } from '../../../redux/features/profile/profileSlice'
import manAvatar from "../../../assets/man-avatar.png"

export default function UserComp() {
    const [open, setOpen] = useState(false)

    const profileState = useSelector((state) => state.profileReducer);


    // let dispatch = useDispatch()


    // useEffect(() => {
    //     getProfileInfo()
    // }, [])


    // let getProfileInfo = () => {
    //     dispatch(profileInfoRequest()).then((res) => {
    //         if (res?.payload?.status !== 200) {
    //             toast.error(res?.payload?.data?.response)
    //         }
    //     }).catch((err) => {

    //     })
    // }

    let logOut = () => {
        // Cookies.remove("org")
        Cookies.remove("babtbu")
        Cookies.remove("authenticated")
        window.location.href = global.appBaseUrl + "login"
    }
    return (
        <div>
            <div className="profile my-xl-5" onClick={() => setOpen(!open)}>

                <div className="d-flex align-items-center justify-content-between">
                    <div className="avatar">
                        {/* <img src={organisationInfoState?.resp?.data?.user?.image} alt="" className="w-100" /> */}
                        <img src={manAvatar} alt="avatar" className="w-100" />
                    </div>
                    <div className="mx-2">
                        <h6 className="add-ellipsis">{profileState?.profileInfo?.data?.name || ""}</h6>
                        <p className="add-ellipsis">{`${profileState?.profileInfo?.data?.contact_first_name || ""} ${profileState?.profileInfo?.data?.contact_last_name || ""}`}</p>
                        {/* <h6 className="add-ellipsis">Prembly</h6>
                        <p className="add-ellipsis">John Doe</p> */}
                    </div>
                </div>
                <span>
                    {open ?
                        <i className="ri-arrow-drop-up-line ri-xl" />
                        :
                        <i className="ri-arrow-drop-down-line ri-xl" />
                    }
                </span>

                {open &&
                    <div className="user-profile">

                        <Link to="/Profile" className="link">
                            <div className="pro py-2">
                                <i className="ri-user-line" />
                                <p>Profile</p>
                            </div>
                        </Link>

                        <div className="logout pt-2" onClick={logOut}>
                            <i className="ri-shut-down-line" />
                            <p>Log out</p>
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}
