import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import MessagesViewPage from "../../components/messages/messageView";
import { useDispatch, useSelector } from "react-redux";
import { allConversationRequest } from "../../redux/features/conversations/conversationsSlice";
import toast from "react-hot-toast";
import { EmptyStateComp } from "../../components/utils";
import CustomPagination from "../../components/customPagination/CustomPagination";
import { MainLoader } from "../../components/utils";
import { useSearchParams } from "react-router-dom";

export default function MessagesPage() {
  const [chatData, setChatData] = useState("active");
  const [activeConvo, setActiveConvo] = useState("");
  const [searchConvo, setSearchConvo] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);

  const conversationState = useSelector((state) => state.conversationsReducer);
  const [searchParams, setSearchParams] = useSearchParams();
  const routeState = useSelector((state) => state.routeReducer);

  const truncateText = (texte, maxLength) => {
    if (!texte) return "";
    if (texte.length <= maxLength) {
      return texte;
    }
    return texte.substr(0, maxLength) + "...";
  };

  let messageData = conversationState?.allConversation?.data;

  messageData = conversationState?.allConversation?.data.filter((item) => {
    if (chatData === "all" || chatData === "all messages") return true;
    return item.status.toLowerCase() === chatData;
  });

  const dispatch = useDispatch();
  const location = useLocation();

  const { state } = location;

  const isPlanExpired = routeState?.isExpired;
  const planName = routeState?.planName;

  const queryParams = new URLSearchParams(location.search);
  let queryRef = queryParams.get("ref") || "";
  const sessionIdQuery = queryParams.get("sessionId") || "";

  const itemsPerPage = 20;

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  let messageSubset =
    itemsPerPage >= messageData?.length
      ? messageData?.slice(0, messageData?.length)
      : messageData?.slice(startIndex, endIndex);

  if (searchConvo) {
    messageSubset = messageData?.filter((item) => {
      if (item?.name?.toLowerCase()?.includes(searchConvo?.toLowerCase())) {
        return item;
      } else if (
        item?.email?.toLowerCase()?.includes(searchConvo?.toLowerCase())
      ) {
        return item;
      }
    });
  }

  useEffect(() => {
    setTotalPages(Math.ceil(messageData?.length / itemsPerPage));
  }, [messageData?.length]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    getConvoList();
    setActiveConvo(queryRef);
  }, []);

  let getConvoList = () => {
    dispatch(allConversationRequest())
      .then((res) => {
        if (res?.payload?.status !== 200) {
          toast.error(res?.payload?.data?.detail);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  let filterData = ["Active", "Closed", "Pending", "All messages"];

  function getFilterCount(filterName) {
    let count = 0;
    if (
      filterName.toLowerCase() === "all" ||
      filterName.toLowerCase() === "all messages"
    )
      return conversationState?.allConversation?.data.length;

    conversationState?.allConversation?.data.map((m) => {
      if (filterName.toLowerCase() === m?.status?.toLowerCase()) count++;
    });
    return count;
  }

  return (
    <div>
      {loading ? (
        <MainLoader />
      ) : (
        <div className="">
          {isPlanExpired && isPlanExpired ? (
            <div style={{ marginTop: "110px" }}>
              <EmptyStateComp
                title="No Active Subscription"
                desc="You have no active subscription, your messages will appear here as soon as you subscribe."
              />
            </div>
          ) : conversationState?.allConversation?.data?.length > 0 &&
            chatData ? (
            <div className="relative flex flex-col h-full">
              <div className="border-b border-1">
                <div className="chat-tab gap-4 p-1 py-5">
                  {filterData?.map((val, i) => (
                    <h5
                      className={
                        "py-3 px-4 !font-inter font-medium !text-[14px] " +
                        (chatData === val?.toLowerCase() ? "active" : "")
                      }
                      key={i}
                      onClick={() => {
                        setChatData(val?.toLowerCase());
                        setCurrentPage(i);
                        setSearchParams({
                          tab: filterData[i].toLowerCase(),
                        });
                      }}
                    >
                      {val}
                      <span
                        className={`inline-block ${chatData === val?.toLowerCase()
                            ? " bg-black text-white "
                            : " bg-[#F0F0F0] text-black"
                          } text-xs font-semibold px-3 py-1  rounded-full ms-2`}
                      >
                        {getFilterCount(val)}
                      </span>
                    </h5>
                  ))}
                </div>
              </div>
              <div className="messages-container grid grid-cols-6 h-full">
                <div className="flex flex-col h-full col-span-2 border-r border-b">
                  <div className="flex items-center border-b border-1 px-4 h-[80px]">
                    <div className="w-full">
                      <input
                        type="search"
                        onChange={(e) => setSearchConvo(e?.target?.value)}
                        className="px-5 py-4 !bg-white shadow-inner border-[0.4px] border-[#C4C4C4] border-opacity-[60%] w-full"
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <div className="flex-grow clist-parent relative h-full overflow-y-auto">
                    <div className="conversationList relative h-full">
                      {messageSubset.map((val, i) => (
                        <div
                          className={`profile relative pl-2 pr-4 py-4 border-b bg-white hover:bg-[#a9a9a9]/20 last-of-type:border-b-0  ${val?.session_id === activeConvo &&
                            "active bg-[#a9a9a9]/20"
                            }`}
                          key={i}
                          onClick={() => setActiveConvo(val?.session_id)}
                        >
                          <div className="inline-flex items-center w-full">
                            <div className="avatar bg-black">
                              <h2 className="text-white uppercase text-[16px] font-medium font-inter">
                                {val?.name.split(" ")[0][0] +
                                  ((val?.name.split(" ")[1] || "")[0] || "")}
                              </h2>
                            </div>
                            <div className="w-full pl-2">
                              <div className="flex justify-between mb-2 ">
                                <small className="font-inter text-[#1C1F00] text-[13px]">
                                  {"customer"}
                                </small>
                                <small className="font-inter text-[#1C1F00] text-[12px]">
                                  {new Date(
                                    val?.time_created
                                  ).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })}
                                </small>
                              </div>
                              <p className="text-sm">
                                <span className="text-sm font-inter">
                                  {val?.name || "Not Available"}:{" "}
                                </span>
                                <span className="text-gray-400 font-inter text-sm">
                                  {truncateText(val?.last_message?.message, 90)}{" "}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="py-5 border-t">
                    {messageSubset.length > 0 && (
                      <CustomPagination
                        onChange={handlePageChange}
                        pageCount={totalPages}
                        initialPage={currentPage}
                      />
                    )}
                  </div>
                </div>
                <div className="col-span-4 ">
                  <div className="md:col-span-9 chat-right-side relative ">
                    {activeConvo || sessionIdQuery ? (
                      <MessagesViewPage
                        sessionIdQuery={sessionIdQuery}
                        planName={planName}
                        convoRef={activeConvo || sessionIdQuery}
                      />
                    ) : (
                      <div className="px-3 h-full">
                        <div className="h-full">
                          <EmptyStateComp
                            title="No conversation selected Yet"
                            desc="Select a conversation so you can see your conversation progress "
                            ctaValue={"Select Conversation"}
                            ctaAction={() =>
                              setActiveConvo(
                                messageData
                                  ?.filter((item) =>
                                    item?.name
                                      ?.toLowerCase()
                                      ?.includes(searchConvo?.toLowerCase())
                                  )
                                  ?.[0]?.session_id
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ marginTop: "110px" }}>
              <EmptyStateComp
                title="No Message yet"
                desc="You have no conversation yet, your messages will appear here as soon as you have some."
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
