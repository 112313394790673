import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import profile from "../../assets/profile.svg";
import { profileInfoRequest } from "../../redux/features/profile/profileSlice";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { FaChevronRight } from "react-icons/fa";
import { authorizationRedirect } from "../../redux/constants/api";

const ProfileLink = () => {
  let orgId = Cookies.get("orgId") || "";

  let location = useLocation();
  const { pathname } = location;
  const [isToggled, setIsToggled] = useState(false);
  const dropdownRef = useRef(null);
  const profileState = useSelector((state) => state.profileReducer);
  
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsToggled(false);
    }
  };
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  let logOut = () => {
    authorizationRedirect();
  };

  const splitLocation = pathname.split("/");
  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div className="profileLink">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
          onClick={() => handleToggle()}
          className="link"
        >
          <div className="h-20 w-20 mt-5 rounded-lg overflow-hidden">
            <img
              src={profileState?.profileInfo?.data?.logo === null ? profile : profileState?.profileInfo?.data?.logo}
              className="max-w-full h-auto"
              alt="profile"
            />
          </div>
          <div className="flex gap-3">
            {profileState?.profileInfo?.data?.name}
            <div className="mt-1">
              <FaChevronRight />
            </div>
          </div>
        </div>
      </div>

      {isToggled && (
        <div
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="py-1" role="none">
            <a
              href="/profiles"
              className="text-gray-700 text-md block px-4 py-2"
              role="menuitem"
              tabIndex="-1"
              id="menu-item-0"
            >
              <i className="ri-user-line mr-3"></i>
              Profile
            </a>
            <button
              onClick={logOut}
              className="text-red-700 text-md block px-4 py-2"
              role="menuitem"
              tabIndex="-1"
              id="menu-item-1"
            >
              <i className="ri-shut-down-line mr-3"></i>
              Logout
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileLink;
