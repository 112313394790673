import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orgSubscriptionHistoryRequest } from "../../../redux/features/subscription/subscriptionSlice";
import toast from "react-hot-toast";
import {
  ActiveTags,
  EmptyStateComp,
  FailedTag,
  InactiveTags,
  PaidTag,
  PendingTag,
} from "../../utils";
import moment from "moment";
import Table from "../../../reuseable/table/Table";
import { FaChevronDown } from "react-icons/fa";
import { LuDownload } from "react-icons/lu";
import CustomPagination from "../../customPagination/CustomPagination";
import Pagination from "../../../reuseable/Pagination";

export default function SubHistoryComp() {
  const isMounted = useRef(false);
  const subscriptionState = useSelector((state) => state.subscriptionReducer);
  const profileState = useSelector((state) => state.profileReducer);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;

  let dispatch = useDispatch();
  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;
    getSubHistory();
  }, []);

  useEffect(() => {
    setTotalPages(
      Math.ceil(subscriptionState?.subHistory?.data?.length / itemsPerPage)
    );
  }, [subscriptionState?.subHistory?.data?.length]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  let getSubHistory = () => {
    dispatch(orgSubscriptionHistoryRequest())
      .then((res) => {
        if (res?.payload?.status === 200) {
          return;
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  const columns = [
    {
      Header: "S/N",
      accessor: "id",
      Cell: (row) => (
        <div className="font-PJ-bold font-medium text-[12px] text-[#424242]">
          {currentPage > 0
            ? itemsPerPage * currentPage + parseInt(row.cell.row.id) + 1
            : parseInt(row.cell.row.id) + 1}
        </div>
      ),
    },
    {
      Header: "Invoice",
      accessor: "name",
      Cell: (row) => (
        <div className="font-PJ-medium font-medium text-[12px] text-[#424242] leading-[15.12px] tracking-[-4%] ">
          {row.cell.row.original.subscription_plan_id.name || "Nil"}
        </div>
      ),
    },
    {
      Header: "Amount",
      accessor: "price",
      Cell: (row) => (
        <div className="font-PJ-medium font-medium text-base text-primary leading-[20.16px] tracking-[-4%]  ">
          {profileState?.profileInfo?.data?.currency}{" "}
          {row.cell.value.toLocaleString() || "Not Available"}
        </div>
      ),
    },

    {
      Header: "Subscription Date",
      accessor: "start_date",
      Cell: (row) => (
        <div className="font-PJ-medium font-medium text-base text-primary leading-[20.16px] tracking-[-4%] ">
          {moment(row.cell.value).format("MMM DD, yyyy") || "Not Available "}
        </div>
      ),
    },
    {
      Header: "Expiry date",
      accessor: "expiration_date",
      Cell: (row) => (
        <div className="font-PJ-medium font-medium text-base text-primary leading-[20.16px] tracking-[-4%] ">
          {moment(row.cell.value).format("MMM DD, yyyy") || "Not Available "}
        </div>
      ),
    },

    {
      Header: "Status",
      accessor: "payment_status",
      Cell: (row) => (
        <div className="font-PJ-bold font-medium text-[12px]">
          {row.cell.value === "Pending" ? (
            <PendingTag />
          ) : row.cell.value === "Failed" ? (
            <FailedTag />
          ) : row.cell.value === "Paid" ? (
            <PaidTag />
          ) : (
            "Not Available"
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="page-desc flex justify-between items-center mt-10 px-3">
        <h3 className=" font-PJ-medium text-xl leading-[25.2px] tracking-[-4%] text-primary ">
          Subscription History
        </h3>
        {/* <div className="flex items-center gap-2">
          <button className="dateBut">
            Jan 02, 2023 - May 02,2023 <FaChevronDown />{" "}
          </button>
          <button className="flex bg-primary text-light px-4 py-2 items-center rounded-lg gap-2">
            {" "}
            <LuDownload />
            Export CSV
          </button>
        </div> */}
      </div>
      {subscriptionState?.subHistory?.data?.length > 0 ? (
        <div className=" ">
          <div className="w-full ">
            <Table
              columns={columns}
              data={subscriptionState?.subHistory?.data}
              currentPage={currentPage}
            />
          </div>
        </div>
      ) : (
        <div style={{ marginTop: "150px" }}>
          <EmptyStateComp
            title="No Subscription yet"
            desc="You have no subscription yet, your subscription history will appear here as soon as you have some."
          />
        </div>
      )}
      <div className="py-2 flex justify-end items-end">
        {subscriptionState?.subHistory?.data?.length > 0 && (
          <Pagination
            onChange={handlePageChange}
            pageCount={totalPages}
            initialPage={currentPage}
          />
        )}
      </div>
    </div>
  );
}
