import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FiUpload } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createBroadcastRequest,
  updateBroadcastRequest,
} from "../../redux/features/broadcast/broadcastSlice";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import CustomCheckboxGroup from "../../reuseable/checkBox/index";
import { BsEye } from "react-icons/bs";
import notfound from "../../assets/state-notfound.svg";
import { FaChevronRight } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import avatar from "../../assets/widgetIcons/portrait-man-cartoon-style.jpg";
import slide from "../../assets/broadcasting/Slide 4_3 - 1.svg";
import sanitizeHtml from "sanitize-html";

const BroadcastComposePage = () => {
  const location = useLocation();
  const { state } = location;
  const { singleBroadcastData } = state || {};
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [title, setTitle] = useState(singleBroadcastData?.title || "");
  const [description, setDescription] = useState(
    singleBroadcastData?.description || ""
  );
  const [broadcastCategories, setBroadcastCategories] = useState(
    singleBroadcastData?.broadcast_category
      ? Array.isArray(singleBroadcastData.broadcast_category)
        ? singleBroadcastData.broadcast_category
        : [singleBroadcastData.broadcast_category]
      : []
  );
  const orgId = Cookies.get("orgId") || "";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const broadcastId = singleBroadcastData?.broadcast_id;
  const profileState = useSelector((state) => state.profileReducer);
  const profileName = profileState?.profileInfo?.data.contact_first_name;
  const companyName = profileState?.profileInfo?.data.name;

  const sanitizedDescription = sanitizeHtml(description, {
    allowedTags: ["p", "br", "b", "i", "u", "a", "ul", "ol", "li"],
    allowedAttributes: {
      a: ["href", "title"],
    },
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSubmit = () => {
    if (!title || !description || broadcastCategories.length === 0) {
      toast.error("Please fill in all fields.");
      return;
    }

    const data = {
      organization_id: orgId,
      broadcast_id: broadcastId,
      title: title,
      description: description,
      broadcast_category: broadcastCategories[0],
      image: selectedFile,
    };

    const action = singleBroadcastData
      ? updateBroadcastRequest
      : createBroadcastRequest;

    dispatch(action(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          toast.success(
            singleBroadcastData
              ? "Broadcast updated successfully"
              : "Broadcast created successfully"
          );
          navigate(-1);
        } else {
          toast.error(res?.payload?.data?.detail || "An error occurred.");
        }
      })
      .catch((err) => {
        toast.error("An error occurred.");
      });
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="px-10 mt-10 ">
      <div className="flex shadow-custom rounded-[20px] bg-[#FDFBFF] justify-between gap-6 xl:gap-14 2xl:gap-32 3xl:gap-60 xl:mt-6 mb-4 mt-2">
        <div className="bg-white border-2 w-[60%] border-[#E7E6E8] p-6 rounded-[20px]">
          <div>
            <h1 className="font-inter text-xl leading-[24px] tracking-[-4%]">
              {singleBroadcastData ? "Edit Broadcast" : "Create Broadcast"}
            </h1>
          </div>

          <div className="mt-5">
            <p className="font-inter text-sm leading-[21px] tracking-[-4%]">
              Enter Broadcast Title
            </p>
            <input
              type="text"
              value={title}
              className="w-full border border-[#DCDFE4] pl-4 mt-2 rounded-lg"
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Kindly provide title"
            />
          </div>
          <div className="mt-5">
            <p className="font-inter text-sm leading-[21px] tracking-[-4%]">
              Description
            </p>
            <ReactQuill
              modules={modules}
              value={description}
              className="reactQuill !w-full !h-full mt-2"
              theme="snow"
              onChange={(content) => setDescription(content)}
              placeholder="Write your description"
            />
          </div>

          <div className="gap-11 mt-[144px]">
            <p className="font-inter text-sm leading-[21px] tracking-[-4%]">
              Choose Category
            </p>
            <CustomCheckboxGroup
              options={[
                { value: "Widget", label: "Widget" },
                { value: "In-App", label: "In-App" },
              ]}
              selectedOptions={broadcastCategories}
              onChange={(selectedOptions) =>
                setBroadcastCategories(selectedOptions)
              }
            />
          </div>

          <div className="mt-5">
            <p className="font-inter text-sm leading-[21px] tracking-[-4%]">
              Upload image (optional)
            </p>
            <div
              className="rounded-lg border flex mt-2 flex-col items-center justify-center px-4 py-8"
              onClick={handleIconClick}
            >
              <div className="mt-5">
                <img src={notfound} alt="" />
              </div>
              <p className="font-inter text-xs leading-[17.21px] opacity-30 text-[#212636] tracking-[-4%]">
                Choose the image you want to upload
              </p>
            </div>
          </div>
          <input
            style={{ visibility: "hidden" }}
            type="file"
            onChange={handleFileChange}
            ref={fileInputRef}
          />
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          ></div>
        </div>
        <div className="w-[40%]">
          <div className="flex bg-white justify-between items-center px-10 py-2 rounded-[10px] border-2 w-full">
            <p>Preview</p>
            <BsEye />
          </div>
          {selectedFile && (
            <div className="px-10">
              <div className="flex justify-center">
                <div className="border mt-5 rounded-lg w-full">
                  {(selectedFile?.type.startsWith("image/") ||
                    singleBroadcastData?.image) && (
                    <div className="flex h-[100%] w-full justify-center">
                      <img
                        className="previewImage rounded-lg"
                        src={
                          selectedFile
                            ? URL.createObjectURL(selectedFile)
                            : singleBroadcastData?.image
                        }
                        alt="File Preview"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex inAppBroadcast p-4 justify-between">
                <div>
                  <p>4 days ago</p>
                  <p>Venixs-Wrapping up the year insight from 2023</p>
                </div>
                <div
                  className={`flex justify-center items-center w-4 h-4 rounded-full border`}
                >
                  <FaChevronRight width={2.25} height={4.5} />
                </div>
              </div>
            </div>
          )}

          {title && (
            <div className="border rounded-lg p-6 mt-4">
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <img className="rounded-full w-10 h-10" src={avatar} alt="" />
                  <p className="font-semiboldInter text-sm ml-[5px] leading-[17.21px]">
                    {profileName} from {companyName}
                  </p>
                </div>
                <div>
                  <IoClose className="text-xl" />
                </div>
              </div>
              <h1 className="font-PJ-medium mt-3 text-[22px] leading-[32px]">
                {title}
              </h1>
              <div className="mt-2">
                {(selectedFile?.type.startsWith("image/") ||
                  singleBroadcastData?.image) && (
                  <div className="flex justify-center">
                    <img
                      className="previewImage rounded-lg"
                      src={
                        selectedFile
                          ? URL.createObjectURL(selectedFile)
                          : singleBroadcastData?.image
                      }
                      alt="File Preview"
                    />
                  </div>
                )}
                <div
                  className="reactQuillPreview text-wrapper"
                  dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-end mb-11">
        <div className="flex gap-5">
          <button
            onClick={handleRefresh}
            className="border font-inter text-[15px] text-[#51376A] leading-[26px] py-2 px-[22px] rounded-lg"
          >
            Refresh
          </button>
          <button
            onClick={handleSubmit}
            className="bg-primary font-inter text-[15px] text-light py-2 px-[22px] rounded-lg leading-[26px]"
          >
            {singleBroadcastData ? " Save changes" : "Create Broadcast"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BroadcastComposePage;
