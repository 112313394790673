import React, { useState, useEffect, useRef } from "react";
import ProfileLink from "../profileLink";
import Search from "../../assets/Search.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Breadcrumb from "./Breadcrumb";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { orgSubscriptionHistoryRequest } from "../../redux/features/subscription/subscriptionSlice";





const Header = ({
  headerTitle,
  breadcrumbs,
  crumbs,
  breadcrumbsIcon,
  to1,
  to2,
  isSubscribed, // whether the user is subscribed
}) => {
  const [daysLeft, setDaysLeft] = useState(0);
  const [trialEnded, setTrialEnded] = useState(false);
  const [subHistory, setSubHistory] = useState([]);
  const [isSubd, setIsSubd] = useState(false); // is subscribed variable
  const [activeSub, setActiveSub] = useState(null);
  const isMounted = useRef(false);

  const dispatch = useDispatch();

  

  const getSubHistory = () => {
    dispatch(orgSubscriptionHistoryRequest())
      .then((res) => {
        if (res?.payload?.status === 200) {
          const subscriptions = res.payload.data;
          setSubHistory(subscriptions);
          checkSubs(subscriptions);
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {
        toast.error("Failed to fetch subscription history.");
      });
  };

  const calculateRemainingDays = (dateString) => {
    const trialEndDate = new Date(dateString);
    const currentDate = new Date();
    const timeDiff = trialEndDate.getTime() - currentDate.getTime();
    const remainingDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return remainingDays;
  };

  const updateRemainingDays = (days) => {
    if (days <= 0) {
      setTrialEnded(true);
      setDaysLeft(0);
    } else {
      setTrialEnded(false);
      setDaysLeft(days);
    }
  };

  const checkSubs = (subs) => {
    let isSubscribed = false;
    subs.forEach((sub) => {
      if (sub.is_active) {
        const remainingDays = calculateRemainingDays(sub.expiration_date) - 1;
        updateRemainingDays(remainingDays);
        setIsSubd(true);
        setActiveSub(sub);
        isSubscribed = true;
      }
    });
    if (!isSubscribed) {
      setTrialEnded(true);
    }
  };

  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;
    getSubHistory();
  }, []);

  return (
    <div className="">
      <div className=" border-b-[0.4px] border-[#E7E7E7] top-0 flex pl-8 pr-10 justify-between items-center">
        <div className="mb-[14px]">
          <div className="HomeTitle !font-PJMedium text-2xl capitalize">
            {headerTitle}
          </div>
          <Breadcrumb />
        </div>
        <div className="flex justify-center items-center gap-12">
          <div className="flex items-center">
            <ProfileLink />
          </div>
        </div>
      </div>
      <div className="">
        {isSubd && daysLeft <= 10 && (
          <div className="upgrade">
            <div className="circularProgress">
              <CircularProgressbar
                counterClockwise
                value={daysLeft}
                minValue={0}
                maxValue={10}
                text={`${daysLeft}`}
                styles={buildStyles({
                  textSize: "36px",
                  pathColor: "#ffffff",
                  trailColor: "#E7D7FF",
                  textColor: "#ffffff",
                })}
              />
            </div>
            <p className="!text-white">
              Days left in your{" "}
              <span>{activeSub?.subscription_plan_id.name}</span> Plan
            </p>
            <a href="/Subscription">
              <button className="bg-white px-4 py-2 rounded-[24px] font-inter font-semibold text-[#54266A]">
                Upgrade Now
              </button>
            </a>
          </div>
        )}
        {trialEnded && !isSubd && (
          <div className="upgrade trialEndedAlert">
            <p className="!text-white">Your trial period has ended</p>
            <a href="/Subscription?tab=plans">
              <button className="bg-white px-4 py-2 rounded-[24px] font-inter  font-semibold text-[#54266A]">
                Subscribe Now
              </button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
