import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import io from "socket.io-client";
// import WebSocket from 'ws';
import { IoMdCloseCircleOutline } from "react-icons/io";
import envelope from "../../../assets/envelope.svg";
import { FaEllipsisV, FaRegTrashAlt } from "react-icons/fa";
import clipboard from "../../../assets/clipboard.svg";
import { IoTicketOutline } from "react-icons/io5";
import toggle from "../../../assets/toggle.svg";
import {
  closeConversationRequest,
  manualFileUpload,
  reopenConversationRequest,
  singleConversationRequest,
  switchConvoHandlerRequest,
} from "../../../redux/features/conversations/conversationsSlice";
import { ActiveTag, ClosedTag, MainLoader, VisitingTag } from "../../utils";
import moment from "moment";
import useSound from "use-sound";
import notifSound from "../../../assets/notif-sound.mp3";
import { createTicketRequest } from "../../../redux/features/ticketing/ticketingSlice";
import Cookies from "js-cookie";
import { subscriptionPlans } from "../../utils/helpers";
import { FaSpinner } from "react-icons/fa";
import { ImAttachment } from "react-icons/im";
import pdflogo from "../../../assets/pdf logo.png";

export default function ConversationsViewPage(props) {
  const isMounted = useRef(false);
  const [moreOptions, setMoreOptions] = useState(false);
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [closeConvoModal, setCloseConvoModal] = useState(false);
  const [reopenConvoModal, setReopenConvoModal] = useState(false);
  const [switchConvoHandlerModal, setSwitchConvoHandlerModal] = useState(false);
  const [switchModalTitle, setSwitchModalTitle] = useState("");

  const [createTicketModal, setCreateTicketModal] = useState(false);
  const [AiMessageModal, setAiMessageModal] = useState(false);
  const [ticketTitle, setTicketTitle] = useState("");
  const [ticketDescription, setTicketDescription] = useState("");

  const [showConvoDetailsModal, setShowConvoDetailsModal] = useState(false);
  const dropdownRef = useRef(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [botOptions, setBotOptions] = useState(false);
  const [botIndex, setBotIndex] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [base64, setBase64] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const[pauseBot, setPauseBot] = useState(false);

  const extensions = [".png", ".jpg", ".jpeg", ".svg"];
  // const [triggerSocket, setTriggerSocket] = useState(false)

  const conversationsState = useSelector((state) => state.conversationsReducer);
  const allTicketsState = useSelector((state) => state.ticketingReducer);
  const [play] = useSound("/assets/notif-sound.mp3", { volume: 0.4 });

  let messageRef = useRef(null);
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let queryRef = queryParams.get("ref") || "";
  const activeTab = queryParams.get("tab") || "";
  const sessionIdQuery = queryParams.get("sessionId") || "";

  let mainConvoref =
    props?.convoRef || queryRef || props.sessionIdQuery || sessionIdQuery;

  let orgId = Cookies.get("orgId") || "";

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }, []);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64(reader.result);
        setSelectedFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const scrollDown = () => {
    // if (messageRef.current) {
    //   	messageRef.current.scrollTop = messageRef.current.scrollHeight;
    // }

    // messageSound();
    messageRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  // const [messageSound] = useSound(notifSound, { volume: 0.1 });
  const handleToggle = () => {
    setMoreOptions(!moreOptions);
  };
  useEffect(() => {
    // if (isMounted.current) return
    // isMounted.current = true
    getConvo();
  }, [mainConvoref, props?.session_id, sessionIdQuery]);

  useEffect(() => {
    // const newSocket = io(global?.apiBaseUrl, {
    const newSocket = io("https://wss-v.venixs.com/", {
      // const newSocket = new WebSocket("wss://0ugdtmwrp8.execute-api.us-east-2.amazonaws.com/dev")

      autoConnect: true, // Disable automatic connection
      reconnection: true, // Enable reconnection
      reconnectionAttempts: 1, // Number of reconnection attempts
      reconnectionDelay: 1000, // Delay between reconnection attempts (in milliseconds)});
    });

    setSocket(newSocket);

    newSocket?.on("connect", () => {
      // newSocket?.addEventListener('open', () => {
      newSocket?.emit("join", mainConvoref);
    });

    newSocket?.addEventListener("send_message", (data) => {
      if (
        data?.room === mainConvoref &&
        data?.messageData?.message_type === "QUERY"
      ) {
        setMessages((prevMessages) => [...prevMessages, data.messageData]);
        play();
        scrollDown();
      }
      if (
        data?.room === mainConvoref &&
        data?.messageData?.message_type === "REPLY" &&
        data?.messageData?.origin === "backend"
      ) {
        setMessages((prevMessages) => [...prevMessages, data.messageData]);
        play();
        scrollDown();
      }
    });

    // newSocket?.addEventListener('pong', (data) => {
    // 	newSocket?.emit("ping", (data) => { });
    // });

    // newSocket?.emit("ping", (data) => {
    // });

    newSocket?.addEventListener("error", (reason) => {
      if (reason === "io server disconnect") {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket?.connect();
      }
    });

    return () => {
      newSocket?.disconnect();
    };
  }, []);

  // const queryParams = new URLSearchParams(location.search);

  // let queryRef = queryParams.get("ref") || "";
  // const sessionIdQuery = queryParams.get("sessionId") || "";

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMoreOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    scrollDown();
  }, [messages]);
  let manualFileUploads = () => {
    let data = {
      conversation_id: mainConvoref,
      message_type: "REPLY",
      origin: "frontend",
      filename: selectedFile.name,
      file: base64,
    };
    let messageData = {
      // conversation_id: mainConvoref,
      message_type: "REPLY",
      origin: "frontend",
      filename: selectedFile.name,
      file_url: base64,
    };
    dispatch(manualFileUpload(data));
    setMessages((prevMessages) => [...prevMessages, messageData]);
  };
  let getConvo = () => {
    let data = {
      session_id: mainConvoref,
    };
    dispatch(singleConversationRequest(data))
      .then((res) => {
        //
        if (res?.payload?.status === 200) {
          setMessages(res?.payload?.data?.messages);

          // scrollDown()
          navigate({
            pathname: `/messages/`,
            search: `?tab=${activeTab}&sessionId=${mainConvoref}`,
          });
        } else {
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((err) => {});
  };

  let switchHandler = () => {
    setIsButtonDisabled(true);

    let data = {
      session_id: mainConvoref,
      pause_bot: switchModalTitle === "Bot" ? false : true,
    };

    dispatch(switchConvoHandlerRequest(data))
      .then((res) => {
        if (res?.payload?.data?.status === true) {
          setSwitchModalTitle("");
          getConvo();
          setSwitchConvoHandlerModal(false);
          toast.success(res?.payload?.data?.detail);
        } else {
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsButtonDisabled(false);
      });
  };

  let closeConvo = () => {
    let data = {
      session_id: mainConvoref,
    };
    dispatch(closeConversationRequest(data))
      .then((res) => {
        if (res?.payload?.data?.status === true) {
          getConvo();
          setCloseConvoModal(false);
          toast.success(res?.payload?.data?.detail);
          return;
        } else {
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((err) => {});
  };

  let reopenConvo = () => {
    let data = {
      session_id: mainConvoref,
    };
    dispatch(reopenConversationRequest(data))
      .then((res) => {
        if (res?.payload?.data?.status === true) {
          getConvo();
          setReopenConvoModal(false);
          toast.success(res?.payload?.data?.detail);
          return;
        } else {
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((err) => {});
  };

  let sendMessage = () => {
    if (selectedFile) {
      manualFileUploads();
    }

    if (messageInput) {
      let messageData = {
        origin: "frontend",
        message: messageInput,
        message_type: "REPLY",
      };
      socket?.emit("send_message", {
        room: mainConvoref,

        messageData,
      });

      setMessageInput("");
    }
    setSelectedFile(null);
    setBase64(null);
  };
  function bytesToMegabytes(bytes) {
    const megabytes = bytes / (1024 * 1024);

    return megabytes.toFixed(2);
  }

  let createTicket = () => {
    if (!ticketTitle) {
      toast.error("Please input a ticket title");
      return;
    }
    if (!ticketDescription) {
      toast.error("Please input a ticket description");
      return;
    }
    let data = {
      title: ticketTitle,
      organisation_id: orgId,
      description: ticketDescription,
      session_id: props?.convoRef,
      // file:ticketFile,
    };
    dispatch(createTicketRequest(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          // getAllTickets()
          toast.success("Ticket created successfully");
          closeCreateModal();
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  let closeCreateModal = () => {
    setTicketTitle("");
    setTicketDescription("");
    setCreateTicketModal(false);
  };
  let closeAiMessageModal = () => {
    setTicketTitle("");
    setTicketDescription("");
    setAiMessageModal(false);
  };

  let handleConvoDetailsModal = () => {
    setShowConvoDetailsModal(!showConvoDetailsModal);
  };

  // let splittedName = conversationsState?.singleConversation?.data?.name?.split(' ')
  return (
    <div className="conversation-view-area relative">
      {switchConvoHandlerModal && (
        <div className="absolute inset-0 z-100 h-full">
          <div className="main-modal backdrop-blur-sm	flex items-center justify-center">
            <div className="main-modal-content shadow-lg bg-white rounded-lg p-4 w-full max-w-md mx-auto">
              <span
                onClick={() => setSwitchConvoHandlerModal(false)}
                className="absolute top-0 right-0 mr-4 mt-4 cursor-pointer"
              >
                <i className="ri-close-line text-gray-400 hover:text-gray-600" />
              </span>
              <div className="card-body">
                <div className="main-modal-body text-center">
                  <h5 className="text-lg font-semibold">
                    Enable {switchModalTitle} Response
                  </h5>

                  <div className="mt-3">
                    {switchModalTitle === "Manual" && (
                      <p>
                        Enabling manual response will disable AI response for
                        this conversation.
                      </p>
                    )}
                    {switchModalTitle === "Bot" && (
                      <p>
                        Enabling Bot response will enable automatic AI response
                        for this conversation.
                      </p>
                    )}
                    <button
                      className="bg-primary text-light rounded-lg px-5 py-3 mt-4"
                      onClick={switchHandler}
                      disabled={isButtonDisabled}
                    >
                      {`Enable ${switchModalTitle} Response`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {closeConvoModal && (
        <div className="absolute inset-0 z-100">
          <div className="main-modal backdrop-blur-sm 	flex items-center justify-center">
            <div className="main-modal-content bg-white rounded-lg p-4 max-w-[514px] mx-auto">
              <span className="absolute top-0 right-0 mr-4 mt-4 cursor-pointer"></span>
              <div className="card-body rounded-5">
                <div className="main-modal-body">
                  <div className="flex py-3 gap-8">
                    <div>
                      <FaRegTrashAlt
                        className="text-7xl p-6 bg-[#FFE6E6] rounded-full"
                        color="#B2431A"
                      />
                    </div>
                    <div>
                      <h5 className="text-2xl text-[#101010] font-PJ-medium text-left mb-3">
                        Are you sure you want to close this conversation?
                      </h5>
                      <p className="text-left font-PJ-regular text-lg mb-5 text-[#5F5F5F]">
                        This will prevent you from sending and receiving
                        messages in this chat. You can always reopen
                        conversation if you need to.
                      </p>
                    </div>
                  </div>

                  <hr className="mb-5 -mx-5" />
                  <div className="flex justify-evenly">
                    <button
                      className="bg-white text-black border px-5 border-gray-400 py-3 w-[186px] rounded-lg"
                      onClick={() => setCloseConvoModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-red-600 text-white py-3 px-5 w-[186px] rounded-lg"
                      onClick={closeConvo}
                    >
                      {" "}
                      Close Conversation
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {reopenConvoModal && (
        <div className="absolute inset-0 z-100">
          <div className="main-modal backdrop-blur-sm	flex items-center justify-center">
            <div className="main-modal-content bg-white rounded-lg p-4 w-full max-w-[514px] mx-auto">
              <div className="card-body rounded-5">
                <div className="main-modal-body text-center">
                  <div className="flex py-3 gap-8">
                    <div>
                      <img
                        className="w-[86px] h-[56]"
                        src={envelope}
                        alt="img"
                      />
                    </div>
                    <div>
                      <h5 className="text-2xl text-[#101010] !font-PJ-medium text-left mb-3">
                        Re-open Conversation{" "}
                      </h5>
                      <p className="text-left font-PJ-regular text-lg mb-5 text-[#5F5F5F]">
                        You are about to reopen this conversation. You will be
                        able to send and receive messages here.
                      </p>
                    </div>
                  </div>
                  <hr className="mb-5 -mx-5" />
                  <div className="flex justify-evenly">
                    <div
                      className="bg-white cursor-pointer text-black border pointer px-5 border-gray-400 py-3 w-[186px] rounded-lg"
                      onClick={() => setReopenConvoModal(false)}
                    >
                      Cancel
                    </div>
                    <button
                      className="bg-green-600 text-white py-3 px-5 w-[186px] rounded-lg"
                      onClick={reopenConvo}
                    >
                      Re-open Conversation
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {createTicketModal && (
        <div className="absolute z-100">
          <div className="main-modal backdrop-blur-sm	flex items-center justify-center">
            <div className="main-modal-content !px-10 bg-white shadow card md:w-[587px] mx-auto">
              <span onClick={closeCreateModal}>
                <i className="ri-close-line close-modal" />
              </span>
              <div className="card-body">
                <div className="main-modal-body">
                  <div className="px-0 pb-5">
                    <h5 className="font-PJ-medium text-[20px]">
                      Create Ticket
                    </h5>
                    <p className="font-inter text-[16px]">
                      {" "}
                      Kindly provide the necessary details{" "}
                    </p>
                  </div>

                  <hr className="-mx-10" />

                  <div className="">
                    <div className="flex flex-col">
                      <label
                        className="font-inter font-medium text-lg"
                        htmlFor=""
                      >
                        Ticket Title
                      </label>
                      <input
                        type="text"
                        value={ticketTitle}
                        required
                        className="form-control border p-4"
                        onChange={(e) => setTicketTitle(e.target.value)}
                        placeholder="Enter ticket title"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label
                        className="font-inter font-medium text-lg"
                        htmlFor=""
                      >
                        Ticket Description
                      </label>
                      <textarea
                        className="form-control border p-4"
                        rows="3"
                        placeholder="Enter ticket description"
                        value={ticketDescription}
                        onChange={(e) =>
                          setTicketDescription(e.target.value.toLowerCase())
                        }
                      ></textarea>
                    </div>

                    <hr className="-mx-10 mt-8 mb-5" />

                    <button
                      className="bg-primary text-light py-3 px-5 w-[186px] rounded-lg"
                      onClick={createTicket}
                    >
                      Create Ticket
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showConvoDetailsModal && (
        <div className="absolute z-100">
          <div className="main-modal backdrop-blur-sm	flex items-start justify-end">
            <div className="main-modal-content !px-5 !pb-0 bg-white shadow card md:w-[438px]">
              <span onClick={handleConvoDetailsModal}>
                <i className="ri-close-line close-modal" />
              </span>
              <div className="card-body">
                <div className="main-modal-body">
                  <div className="convo-settings ps-0">
                    <div className="border-0 pb-5">
                      <div className="card-header py-4 mb-3">
                        <h5 className="font-PJ-semibold text-xl mb-2">
                          Conversation Details
                        </h5>
                        <p className="text-[#323232] font-inter text-sm">
                          {" "}
                          You can find the neccesary details below{" "}
                        </p>
                      </div>

                      <hr className="-mx-5 mb-4" />

                      <div className="card ">
                        <div className="card-body border rounded-lg p-5 pt-6">
                          <div className="profile-info-area border-b-2 border-gray-200 rounded-b-lg p-3 flex items-center justify-between">
                            <div className="flex items-center justify-center">
                              <div className="avatar !w-[48px] !h-[48px] flex items-center justify-center !mr-3">
                                <h1>
                                  {
                                    conversationsState?.singleConversation?.data
                                      ?.name[0]
                                  }
                                </h1>
                              </div>
                              <div className="mt-0 text-start">
                                <h5 className="truncate font-medium text-[#181B01] text-lg">
                                  {conversationsState?.singleConversation?.data
                                    ?.name || "Not Available"}
                                </h5>
                                <p className="truncate text-[12px] font-inter text-[#1C1F00]">
                                  {conversationsState?.singleConversation?.data
                                    ?.email || "Not Available"}
                                </p>
                              </div>
                            </div>

                            <div className="convo-detail">
                              {/* <i className="ri-magic-line" />
                              <div className="col">
                                <small className="text-color-light">
                                  Conversation Status
                                </small>
                              </div> */}
                              <h6>
                                {conversationsState?.singleConversation?.data
                                  ?.status === "active" && <ActiveTag />}
                                {conversationsState?.singleConversation?.data
                                  ?.status === "closed" && <ClosedTag />}
                                {conversationsState?.singleConversation?.data
                                  ?.status === "pending" && <VisitingTag />}
                              </h6>
                            </div>
                          </div>

                          {/* <hr className="my-5 " /> */}

                          <div className="mt-5 convo-details-area">
                            <div className="grid grid-cols-2 gap-4">
                              <div className="convo-detail col-span-1">
                                <i
                                  className="ri-map-pin-line"
                                  style={{ color: "#51376A" }}
                                />
                                <div className="col">
                                  <small className="font-PJ-light font-light text-[#595959] text-sm">
                                    Location
                                  </small>
                                  <h6 className="font-inter font-medium text-sm text-[#525150] break-all">
                                    {conversationsState?.singleConversation
                                      ?.data?.location || "Not Available"}
                                  </h6>
                                </div>
                              </div>
                              <div className="convo-detail col-span-1">
                                <i
                                  className="ri-links-line"
                                  style={{ color: "#51376A" }}
                                />
                                <div className="col">
                                  <small className="font-PJ-light font-light text-[#595959] text-sm">
                                    Messaging from
                                  </small>
                                  <h6 className="font-inter font-medium text-sm text-[#525150] break-all">
                                    {conversationsState?.singleConversation
                                      ?.data?.source || "Not Available"}
                                  </h6>
                                </div>
                              </div>
                              <div className="convo-detail col-span-1">
                                <i
                                  className="ri-map-pin-range-line"
                                  style={{ color: "#51376A" }}
                                />
                                <div className="col">
                                  <small className="font-PJ-light font-light text-[#595959] text-sm">
                                    IP Address
                                  </small>
                                  <h6 className="font-inter font-medium text-sm text-[#525150] break-all">
                                    {conversationsState?.singleConversation
                                      ?.data?.ip || "Not Available"}
                                  </h6>
                                </div>
                              </div>
                              <div className="convo-detail col-span-1">
                                <i
                                  className="ri-time-line"
                                  style={{ color: "#51376A" }}
                                />
                                <div className="col">
                                  <small className="font-PJ-light font-light text-[#595959] text-sm">
                                    Initiated At
                                  </small>
                                  <h6 className="font-inter font-medium text-sm text-[#525150] break-all">
                                    {moment(
                                      conversationsState?.singleConversation
                                        ?.data?.time_created
                                    ).format("LL") || "Not Available"}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="convo-detail mt-5">
                              <i
                                className="ri-device-line"
                                style={{ color: "#51376A" }}
                              />
                              <div className="col">
                                <small className="font-PJ-light font-light text-[#595959] text-sm text-left">
                                  Device
                                </small>
                                <h6 className="font-inter font-medium text-sm text-[#525150] break-word">
                                  {conversationsState?.singleConversation?.data
                                    ?.device || "Not Available"}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {conversationsState?.isLoading ? (
        <MainLoader />
      ) : (
        <div className="messagesView border-b">
          <div className="px-0 h-full">
            <div className=" h-full">
              <div className="card-header bg-white border-b border-1 px-2">
                <div className="flex h-full justify-between">
                  <div className="flex items-center">
                    <div className="profile flex items-center">
                      <div className="avatar bg-light font-inter font-medium !border-0 !mr-3">
                        <h2>
                          {
                            conversationsState?.singleConversation?.data
                              ?.name[0]
                          }
                        </h2>
                      </div>
                      <div className="">
                        <h5 className="truncate font-inter text-lg font-medium m-0">
                          {conversationsState?.singleConversation?.data?.name ||
                            "Not Available"}
                        </h5>
                        <small className="truncate font-inter ">
                          {conversationsState?.singleConversation?.data
                            ?.email || "Not Available"}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="more-dropdown">
                      <div className="relative">
                        <i
                          className="ri-more-2-fill ri-xl cursor-pointer border rounded-md p-2 mr-5"
                          onClick={handleToggle}
                        />

                        {moreOptions && (
                          <div
                            ref={dropdownRef}
                            className="absolute top-full font-inter px-4 py-2 right-7 mt-4 w-[263px] items-center bg-white border rounded-lg shadow-lg"
                          >
                            {/* Dropdown options */}
                            <div
                              className="flex items-center p-2 py-3 border-b border-1 cursor-pointer"
                              onClick={handleConvoDetailsModal}
                            >
                              <img className="mr-2" src={clipboard} alt="" />

                              <p className="mb-0">Conversation Details</p>
                            </div>

                            {!conversationsState?.singleConversation?.data
                              ?.pause_bot && (
                              <div
                                className="flex items-center p-2 py-3 border-b border-1 cursor-pointer"
                                onClick={() => {
                                  setSwitchConvoHandlerModal(true);
                                  setSwitchModalTitle("Manual");
                                }}
                              >
                                <img className="mr-2" src={toggle} alt="img" />
                                <p className="mb-0"> Enable Manual Response</p>
                              </div>
                            )}

                            {conversationsState?.singleConversation?.data
                              ?.pause_bot && (
                              <div
                                className="flex items-center p-2 py-3 border-b border-1 cursor-pointer"
                                onClick={() => {
                                  setSwitchConvoHandlerModal(true);
                                  setSwitchModalTitle("Bot");
                                }}
                              >
                                <img className="mr-2" src={toggle} alt="img" />

                                <p className="mb-0"> Enable Bot Response</p>
                              </div>
                            )}

                            {(props.planName ===
                              subscriptionPlans.PROFESSIONAL ||
                              props.planName ===
                                subscriptionPlans.ADVANCED) && (
                              <div
                                className="flex items-center p-2 py-3 border-b border-1 cursor-pointer"
                                onClick={() => setCreateTicketModal(true)}
                              >
                                <IoTicketOutline
                                  color={"#323232"}
                                  className="text-2xl mr-2"
                                />

                                <p className="mb-0">Create Ticket</p>
                              </div>
                            )}

                            <div
                              className="flex items-center p-2 py-3 cursor-pointer"
                              onClick={() => {
                                conversationsState?.singleConversation?.data
                                  ?.status === "closed"
                                  ? setReopenConvoModal(true)
                                  : setCloseConvoModal(true);
                              }}
                            >
                              <IoMdCloseCircleOutline
                                color={
                                  conversationsState.singleConversation.data
                                    .status === "closed"
                                    ? "green"
                                    : "red"
                                }
                                className="text-2xl mr-2"
                              />

                              <p
                                className={`mb-0 ${
                                  conversationsState.singleConversation.data
                                    .status === "closed"
                                    ? "text-[green]"
                                    : "text-[red]"
                                }`}
                              >
                                {conversationsState?.singleConversation?.data
                                  ?.status === "closed"
                                  ? "Re-open Conversation"
                                  : "Close Conversation"}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="convo bg-white h-full px-5 py-2 overflow-y-auto">
                <div className="card-body px-md-4">
                  <div className="message-date-area text-center mb-4">
                    <small className="bg-[#F1F1F1] text-[#1C1F00] font-bold p-1 px-3 rounded-lg">
                      {moment(
                        conversationsState?.singleConversation?.data
                          ?.time_created
                      ).format("LL")}
                    </small>
                  </div>

                  {messages?.map((val, index) => (
                    <div key={index}>
                      <div className="flex my-4  ">
                        {val?.message_type !== "REPLY" && (
                          <div
                            className={`avatar !mr-3 w-[35px] h-[35px] flex items-center justify-center rounded-full bold bg-light`}
                          >
                            <h2>
                              {
                                conversationsState?.singleConversation?.data
                                  ?.name[0]
                              }
                            </h2>
                          </div>
                        )}

                        <div
                          className={
                            val?.message_type === "REPLY"
                              ? "answer-area text-[14px] font-inter"
                              : "question-area text[14px] font-inter"
                          }
                          onMouseOver={() => {
                            val?.author === "BOT" && setBotOptions(true);
                            setBotIndex(index);
                          }}
                          onMouseLeave={() =>
                            val?.author === "BOT" && setBotOptions(false)
                          }
                        >
                          <div className="flex gap-4">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: val?.message?.replace(/\n/g, "<br>"),
                              }}
                            />
                            {extensions.some(
                              (extension) =>
                                val?.file_url?.includes(extension) ||
                                val?.filename?.includes(extension)
                            ) ? (
                              <a
                                href={val?.file_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  className="object-contain"
                                  width={200}
                                  height={200}
                                  alt="img"
                                  src={val?.file_url}
                                />
                              </a>
                            ) : (
                              val?.file_url && (
                                <a
                                  href={val?.file_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    className="object-contain"
                                    width={200}
                                    height={200}
                                    alt="img"
                                    src={pdflogo}
                                  />
                                </a>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          val?.message_type === "REPLY" ? "text-end" : ""
                        }
                      >
                        {/* <small>
                            {moment(val?.time_created).format("LT")}
                          </small> */}
                      </div>
                    </div>
                  ))}
                  <div ref={messages[messages.length - 1] && messageRef} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex items-center justify-center mx-10">
        {!conversationsState?.singleConversation?.data?.pause_bot ? (
          <button
            className="bg-primary text-light rounded-lg px-5 py-3 mt-4"
            onClick={switchHandler}
          >
            Switch to manual chat
          </button>
        ) : (
          <div className="flex mt-3 gap-10 items-center justify-evenly w-full">
            <div className="">
              <div
                className={`avatar w-[35px] h-[35px] flex items-center justify-center rounded-full bold bg-light`}
              >
                <h2>{conversationsState?.singleConversation?.data?.name[0]}</h2>
              </div>
            </div>
            <div className="flex items-center input-message px-4 py-0 w-full">
              <div className="relative w-full">
                <textarea
                  onChange={(e) => {
                    setMessageInput(e?.target?.value);
                  }}
                  value={messageInput}
                  className="w-full form-input border-0 p-3"
                  rows="1"
                  placeholder={selectedFile ? "" : "Type your message here"}
                ></textarea>
                {selectedFile && (
                  <div className="absolute px-3 text-[10px] py-2 bg-gray-100 rounded-md top-1 left-7 mt-2 mr-2 flex items-center space-x-2">
                    <i className="ri-file-3-fill ri-xl text-gray-500">
                      {selectedFile?.name} &nbsp;{" "}
                      <span className="text-[8px]">
                        ({bytesToMegabytes(selectedFile?.size)} MB)
                      </span>{" "}
                    </i>
                    <button
                      onClick={() => setSelectedFile(null)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <i className="ri-close-circle-fill ri-xl"></i>
                    </button>
                  </div>
                )}
              </div>
              {(messageInput || selectedFile) && (
                <i
                  onClick={sendMessage}
                  className="ri-send-plane-2-fill ri-xl mx-2 cursor-pointer"
                />
              )}
            </div>
            <div
              className="flex items-center justify-center border border-[#ccc] rounded-full w-[50px] h-[45px]"
              onClick={() => fileInputRef.current.click()}
            >
              <ImAttachment />
            </div>
          </div>
        )}
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
    </div>
  );
}
